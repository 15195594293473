<!-- =========================================================================================
  File Name: DataListThumbView.vue
  Description: Data List - Thumb View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-thumb-view" class="data-list-container">
    <vs-table
      ref="table"
      search
      :max-items="itemsPerPage"
      :data="users"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <!-- ITEMS PER PAGE -->
        <div class="flex">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="
                p-4
                border border-solid
                d-theme-border-grey-light
                rounded-full
                d-theme-dark-bg
                cursor-pointer
                flex
                items-center
                justify-between
                font-medium
              "
            >
              <span class="mr-2"
                >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
                {{
                  users.length - currentPage * itemsPerPage > 0
                    ? currentPage * itemsPerPage
                    : users.length
                }}
                of {{ queriedItems }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="itemsPerPage = 4">
                <span>4</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 10">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 15">
                <span>15</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 20">
                <span>20</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
      </div>

      <template slot="thead">
        <vs-th>الصورة</vs-th>
        <vs-th sort-key="name">الإسم</vs-th>
        <vs-th sort-key="phone">رقم الهاتف</vs-th>
        <vs-th>عمليات</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td
              class="table-image-container flex justify-center items-center"
            >
              <img :src="tr.image" class="table-image" />
            </vs-td>
            <vs-td>
              <p class="user-name font-medium truncate">{{ tr.name }}</p>
            </vs-td>

            <vs-td>
              <p class="userphone">{{ tr.phone }}</p>
            </vs-td>
              <vs-td class="whitespace-no-wrap">
              <feather-icon
                icon="RepeatIcon"
                svgClasses="w-5 h-5 hover:text-warning stroke-current"
                title="استعادة"
                @click.stop="openConfirmRetrieve(tr.id)"
              />
            </vs-td>
          </vs-tr>
        
        </tbody>
      </template>
    </vs-table>
    <vs-pagination
        class="mt-4"
        :total="lastPage"
        v-model="current_Page"
        @change="getData(current_Page)"
      ></vs-pagination>
  </div>
</template>

<script>
import {
    mapActions
  } from "vuex"
export default {
  data() {
    return {
      search: "",
      itemsPerPage:10,
      selected_id:''
    };
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.users.length;
    },
    users(){
      return this.$store.state.users.archived_sellers
    },
    lastPage() {
      return this.$store.state.orders.pagination;
    },
  },

  methods: {
    ...mapActions('users', [
        'fetchArchivedSellers',
        'retrieveUser',
      ]),
    openConfirmRetrieve(id) {
      this.selected_id = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: 'تأكيد',
        text: ` هل انت متأكد من استعادة هذا المستخدم؟  `,
        accept: this.retrieveData,
        acceptText: 'نعم',
        cancelText: 'الغاء',
      });
    },
    retrieveData() {
      this.retrieveUser(this.selected_id)
        .then((response) => {
          this.fetchArchivedSellers();
          this.successDialog(response.data.message);
        })
        .catch((error) => {
         this.errorDialog(error);
        });
    },
    
  },
  created() {
     this.fetchArchivedSellers()
  },
};
</script>

<style lang="scss">
#data-list-thumb-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

        td {
          padding: 10px;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }

          &.table-image-container {
            height: 110px;

            // background: #fff;

            span {
              display: flex;
              justify-content: flex-start;
            }

            .table-image {
              height: 68px;
            }
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
